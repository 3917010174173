<template>
    <div id="page-container"
         class="sidebar-o sidebar-dark enable-page-overlay side-scroll page-header-fixed main-content-narrow side-trans-enabled"
         :class="[
            {'page-header-dark dark-mode': locState.darkModeActive},
            {'sidebar-mini': locState.sidebarMini}
            ]"
    >
        <div id="page-overlay"></div>
        <nav id="sidebar" class="d-flex flex-column" aria-label="Main Navigation">
            <div class="content-header ">
                <Link class="fw-semibold text-dual" href="/">
                      <span class="smini-visible">
                        <i class="fa fa-circle-notch text-primary"></i>
                      </span>
                    <span class="smini-hide fs-5 tracking-wider">PrivyTrips</span>
                </Link>
                <div>
                    <button type="button" class="btn btn-sm btn-alt-secondary" data-toggle="layout"
                            data-action="dark_mode_toggle"
                            @click="toggleDarkMode"><i class="far fa-moon"></i></button>
                    <a class="d-lg-none btn btn-sm btn-alt-secondary ms-1" data-toggle="layout"
                       data-action="sidebar_close"
                       href="javascript:void(0)">
                        <i class="fa fa-fw fa-times"></i>
                    </a>
                </div>
            </div>
            <div class="app-main-layout-sidebar d-flex flex-column">
                <div class="sidebar-user">
                    <div class="sidebar-user-avatar">
                        <img :src="getAuthUser.avatar ?? '../images/auth-user-default-img.svg'" alt="">
                    </div>
                    <div class="sidebar-user-name">{{ getAuthUser.name }}</div>
                    <h4>${{getAuthUser.total}}</h4>
                </div>
                <div class="content-side ">
                    <ul class="nav-main">
                        <template v-for="item in menuList">
                            <li  style="" class="nav-main-item  " :class="item?.class" v-if="item.allowed && item.show">
<!--                                <Link class="nav-main-link" :class="getActive(item.title)" :href="item.link?? '#'" :target="item.target ? item.target : ''">-->
                                <a class="nav-main-link" :class="getActive(item.title)" :href="item.link?? '#'" :target="item.target ?? '_self'">
                                    <acms-v-icon class="nav-main-link-icon" :name="item.icon"></acms-v-icon>
                                    <span class="nav-main-link-name"> {{ item.title }}  </span>
                                </a>
                            </li>
                        </template>
                    </ul>
                </div>
            </div>
            <div class="sidebar-footer mt-auto  ">
                        <a  href="#" class="nav-main-link"  @click="showLogoutModal">
                            <acms-v-icon class="nav-main-link-icon" name="arrow-right-to-bracket"></acms-v-icon>
                            <span class="nav-main-link-name"> LogOut  </span>
                        </a>
                <div class="sidebar-app-version pt-4 pb-4 content-side ">
                   <span> CyberCraft v1.1.1</span>
                </div>
            </div>
        </nav>
        <header id="page-header">
            <div class="content-header  ">
                <acms-v-btn icon="collapse" icon-size="20" class="btn-alt-secondary"
                            style="padding: 6px"
                            :iconColor="locState.darkModeActive ? 'text-white' :''"
                            @click="locState.sidebarMini = !locState.sidebarMini"></acms-v-btn>
                <div class="ms-auto">
                    <slot name="header-right"></slot>
                </div>
                <v-btn v-if="shouldRenderButton" color="primary" @click="layoutStore.openReminderDialog">Open Reminders</v-btn>
                <ReminderDialog v-if="shouldRenderReminder"/>
            </div>
            <div id="page-header-search" class="overlay-header bg-body-extra-light">
                <div class="content-header">
                    <form class="w-100" action="be_pages_generic_search.html" method="POST">
                        <div class="input-group">
                            <button type="button" class="btn btn-alt-danger" data-toggle="layout"
                                    data-action="header_search_off">
                                <i class="fa fa-fw fa-times-circle"></i>
                            </button>
                            <input type="text" class="form-control" placeholder="Search or hit ESC.."
                                   id="page-header-search-input" name="page-header-search-input">
                        </div>
                    </form>
                </div>
            </div>
            <div id="page-header-loader" class="overlay-header bg-body-extra-light">
                <div class="content-header">
                    <div class="w-100 text-center">
                        <i class="fa fa-fw fa-circle-notch fa-spin"></i>
                    </div>
                </div>
            </div>
        </header>
        <main id="main-container">
            <div class="bg-body-light   ">
                <div class="content  p-4 d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center ">
                    <div class="flex-grow-1">
                        <h3 class=" lh-base fw-bold   mb-0">
                            {{ title ?? 'Page title' }}
                        </h3>
                        <template v-if="title2">
                            <h5>
                                {{title2}}
                            </h5>
                        </template>
                        <h4>
                        </h4>
                    </div>
                    <template v-if="breadcrumbs">
                        <nav class="flex-shrink-0 mt-3 mt-sm-0 ms-sm-3" aria-label="breadcrumb">
                            <ol class="breadcrumb breadcrumb-alt">
                                <li class="breadcrumb-item fw-bold">
                                    <Link class="link-fx" :href="breadcrumbs?.[0].link ?? '#'">
                                        {{ breadcrumbs?.[0].title ?? 'Breadcrumbs' }}
                                    </Link>
                                </li>
                                <li class="breadcrumb-item fw-bold" aria-current="page">
                                    {{ breadcrumbs?.[1] ?? 'Breadcrumbs' }}
                                </li>
                            </ol>
                        </nav>
                    </template>
                </div>
            </div>
            <div class="app-main-layout-container content" id="main-app">
                <slot name="content"></slot>
            </div>
            <div class="app-main-layout-alerts-manager"  >
                <transition-group name="slide">
                    <template v-for="(item,index) in AlertsManager.list" :key="index">
                        <acms-v-alert
                            :content="item.content"
                            :type="item.type"
                            @remove="AlertsManager.remove(index)"
                        />
                    </template>
                </transition-group>
            </div>
        </main>
        <acms-v-modal-alert
            v-model:show="locState.logoutModal"
            title="Log Out"
            text="Are you sure you want to logout?"
            leftButtonTitle="Cancel"
            rightButtonClass="btn-danger"
            rightButtonTitle="Yes, logout"
            @leftButtonClick="locState.logoutModal = false"
            @rightButtonClick="logoutHandle"
        />

        <Teleport to="body">
            <debug-modal/>
        </Teleport>
    </div>
</template>
<script>export default {name: 'AppMainLayout',};</script>
<script setup="">
import {Link, router, usePage} from '@inertiajs/vue3';
import {reactive, onBeforeMount, onMounted, onBeforeUnmount, computed, ref} from 'vue';
import {useAlertsManagerStore} from "@store/plugins/alerts-manager";
import LocalStorageService from "@services/LocalStorageService";
import {getRoute} from "../../../plugins/useRoutes";
import DebugModal from "../../ui/DebugModal.vue";
import ReminderDialog from "@layouts/ReminderDialog.vue";
import {useLayoutStore} from "@store/pages/layout-store";
import {useBackendMiddlewarePropsService} from "@services";

const props = defineProps({title: String, title2:String, breadcrumbs: Array});
const $page = usePage()
const AlertsManager = useAlertsManagerStore()
const locState = reactive({
    darkModeActive: false,
    sidebarMini: false,
    successVisit:null,
    logoutModal:false,
});
const layoutStore = useLayoutStore();
const shouldRenderButton = computed(() => {
    return !$page.url.includes('requests/edit') && !$page.url.includes('reminders') ; // Убираем кнопку, если URL содержит 'requests/edit'
});
const shouldRenderReminder = computed(() => {
    return  !$page.url.includes('reminders') ; // Убираем кнопку, если URL содержит 'requests/edit'
});
const showDialog = ref(false);
const getAuthUser = computed(()=>{
    const user = $page.props.auth_user
    const {display_name, display_last_name} = user.work_data
    return {
        name: display_name+' '+display_last_name,
        first_name: display_name,
        avatar:user.avatar,
        total: '0',
        id: user.id
    }
})
const backPerms = useBackendMiddlewarePropsService();
const menuList = ref([
    {
        title: 'Run Unifire',
        link: getRoute('unifire'),
        icon: 'unifire',
        target: '_blank',
        allowed: true, //$page.props.sidebarAllowedModules?.users
        show:backPerms.backendPermissions.allowUnifireButtonDev
    },
    // {
    //     title: 'Run Unifire (PROD)',
    //     link: '/admin/token-production',
    //     icon: 'unifire',
    //     target: '_blank',
    //     allowed: true, //$page.props.sidebarAllowedModules?.users
    //     show: backPerms.backendPermissions.allowUnifireButtonProd
    // },
    {
        title: 'GDS',
        link: '/gds',
        icon: 'unifire',
        allowed: true, //$page.props.sidebarAllowedModules?.users
        show:backPerms.backendPermissions.allowUnifireButtonDev
    },
    // {
    //     title: 'GDS',
    //     link: '/gds',
    //     icon: 'unifire',
    //     allowed: true, //$page.props.sidebarAllowedModules?.users
    // },
    {
        title: 'Clients',
        link: '/clients',
        icon: 'user-solid',
        allowed: true, //$page.props.sidebarAllowedModules?.users
        show:true
    },
    {
        title: 'Requests',
        link: '/requests',
        icon: 'request',
        allowed: true, //$page.props.sidebarAllowedModules?.key
        show:true
    },
    {
        title: 'Unassigned Requests',
        link: '/unassigned',
        icon: 'calendar',
        // class:'nav-main-item--disabled',
        allowed: true, //$page.props.sidebarAllowedModules?.key
        show:true
    },
    {
        title: 'Reminders',
        link: '/reminders',
        icon: 'calendar',
        // class:'nav-main-item--disabled',
        allowed: true, //$page.props.sidebarAllowedModules?.key
        show:true
    },
    {
        title: 'Task manager',
        link: '/tasks',
        icon: 'clipboard',
        class:'nav-main-item--disabled',
        allowed: true, //$page.props.sidebarAllowedModules?.key
        show:true
    },
    {
        title: 'Settings',
        link: '#',
        icon: 'settings',
        class:'nav-main-item--disabled',
        allowed: true, //$page.props.sidebarAllowedModules?.key
        show:true
    },
])
const toggleDarkMode = () => {
    locState.darkModeActive = !locState.darkModeActive;
    LocalStorageService.set('AcmsDarkMode', locState.darkModeActive)
};
const getActive = (str) => {
    const currentUri = $page.props.global.currentUri
    return {active: currentUri.includes(str.toLowerCase().replaceAll(' ', '-'))}
}
onMounted(()=>{
    locState.successVisit =  router.on('success', (event) => {
        if($page.props.flash?.error){
            AlertsManager.add($page.props.flash?.error, 'danger')
        }
    })
})
const showLogoutModal = () =>{locState.logoutModal = true}
const logoutHandle = () => {
    locState.logoutModal = false
    window.history.replaceState(false, false, '/admin/login');
    window.location.href = '/admin/login'
}
onBeforeUnmount(()=>{
    locState.successVisit?.()
})
onBeforeMount(()=>{
    locState.darkModeActive = LocalStorageService.get('AcmsDarkMode') ?? false
})
</script>

<style lang="scss">
#page-header .content-header {
    padding: 0 24px;
}
.app-main-layout-container {
    width: 100%;
    max-width: 1632px; // 1632
    margin: 0 auto;
    padding: 24px;
}
.app-main-layout-header {
    height: 100px;
}
.nav-main-item--disabled{
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
}
.app-main-layout-sidebar {
    .sidebar-user {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        background-color: #19212C;
        &-avatar {
            border-radius: 50%;
            border: 5px solid #525C69;
            overflow: hidden;
            margin-bottom: 16px;
            img {
                width: 64px;
                height: 64px;
                object-fit: cover;
            }
        }
        &-name {
            font-weight: 500;
            line-height: 20px;
            margin-bottom: 8px;
        }
        &-role {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            padding: 2px 6px;
            color: #F8D4D4;
            border-radius: 4px;
            background-color: #DC2626;
        }
    }
}
.nav-main .nav-main-link.active {
    background-color: #E9ECEF;
    &:hover {
        background-color: #E9ECEF !important;
    }
    span {
        color: #1F2937;
    }
    .nav-main-link-icon {
        color: #1F2937 !important;
    }
}
.app-main-layout-content {
    width: 100%;
}
.app-main-layout-alerts-manager {
    position: fixed;
    right: 16px;
    top: 16px;
    z-index: 10000;
    width: 360px;
}
</style>


