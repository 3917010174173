<template>
    <div class="pq-airline-taxes-item p-3">
        <div class="fw-bold mb-1">{{ title }}</div>
        <template v-for="([passengerKey, passengerValue]) in passengers.entries">
            <template v-if="passengerValue > 0 && modelValue && passengerKey in modelValue">
                <template v-if="disabledInputs">
                    <div class="form-row">
                        <acms-v-form-field
                            :label="label"
                            :placeholder="placeholder"
                            type="number"
                            :modelValue="modelValue[passengerKey].price"
                            :mode-input="{symbolBeforeContent: modelValue[passengerKey].price && String(modelValue[passengerKey].price)?.length > 0 ? '$' : ''}"
                            :name="getFieldName(passengerKey)"
                            :errors="errors"
                            disabled
                        />
                        <!-- Условное добавление нового поля -->
                        <template v-if="showAdditionalField">
                            <acms-v-form-field
                                :label="`Min. M/U ${passengerKey}`"
                                :placeholder="`Min. M/U ${passengerKey}`"
                                type="number"
                                :modelValue="modelValue[passengerKey].mark_up"
                                @update:modelValue="(val) => updateValueHandle(parseFloat(val), passengerKey, 'mark_up')"
                                :mode-input="{symbolBeforeContent: modelValue[passengerKey].mark_up && String(modelValue[passengerKey].mark_up)?.length > 0 ? '$' : ''}"
                                :name="getFieldName(passengerKey)"
                                :errors="errors"
                            />
                        </template>
                    </div>
                </template>
                <template v-else>
                    <div class="form-row">
                        <template v-if="showAdditionalField">
                            <acms-v-form-field
                                :label="`Suggested Min. M/U`"
                                :placeholder="`$0`"
                                type="number"
                                :modelValue="modelValue[passengerKey].mark_up"
                                @update:modelValue="(val) => updateValueHandle(parseFloat(val), passengerKey, 'mark_up')"
                                :mode-input="{symbolBeforeContent: modelValue[passengerKey].mark_up && String(modelValue[passengerKey].mark_up)?.length > 0 ? '$' : ''}"
                                :name="getFieldName(passengerKey)"
                                :errors="errors"
                            />
                        </template>
                        <acms-v-form-field
                            :label="label"
                            :placeholder="getPlaceholder(passengerKey)"
                            type="string"
                            :modelValue="modelValue[passengerKey]?.price"
                            @update:modelValue="(val)=>{
                                val = String(val);
                                if (val.includes(',')) {
                                    val = val.replace(',', '.');
                                }
                                updateValueHandle(parseFloat(val), passengerKey, 'price')
                            }"
                            :mode-input="{
                                limitAfterDecimalPoint: 2,
                                symbolBeforeContent: props.modelValue[passengerKey].price && String(props.modelValue[passengerKey].price)?.length > 0 ? '$' : ''
                            }"
                            :name="getFieldName(passengerKey)"
                            :errors="errors"
                        />
                        <!-- Условное добавление нового поля -->
                    </div>
                </template>
            </template>
        </template>
    </div>
</template>

<script>
export default {
    name: "PartialPqTaxesSingleItem",
};
</script>

<script setup="">
import numberHelpers from "@helpers/numberHelpers";

const emit = defineEmits(['update:modelValue']);
const props = defineProps({
    passengers: Object,
    label: String,
    title: String,
    placeholder: String,
    modelValue: Object,
    rootName: String,
    errors: Object,
    disabledInputs: Boolean,
    minPricesForSell: Object,
    showAdditionalField: Boolean, // Новый prop
});

if(!props.showAdditionalField) {
    console.log('noweq', props.modelValue);
}
const updateValueHandle = (val, key, type = null) => {
    const locValue = props.modelValue;
    // if (props.showAdditionalField) {
        locValue[key][type] = val;


    emit('update:modelValue', locValue);
};

const getFieldName = (passengerKey) => `${props.rootName}.${passengerKey}`;

const getPlaceholder = (key) => {
    if (props.minPricesForSell) {
        const newPlaceholder = numberHelpers.getFixed(Number(props.minPricesForSell?.[key]));
        return '$' + String(newPlaceholder).replace('.', ',');
    }
    return props.placeholder;
};
</script>

<style scoped>
.form-row {
    display: flex;
    align-items: center;
    gap: 16px; /* Расстояние между полями */
}

.form-row > * {
    flex: 1; /* Равномерное распределение ширины полей */
}
</style>
