<template>
    <div class="partial-price-quotes-tables">
        <div class="pq-table-desktop ">
            <div class="pq-tables1-item-content-inner">
<!--                <table class="pq-table1 first-and-top w-100 align-self-start">-->
<!--                    <thead>-->
<!--                    <tr class="bg-gray-lighter">-->
<!--                        <template v-for="item in getTableShorInfo">-->
<!--                            <th :style="item?.style" v-if="item.content" class="text-uppercase">{{ item.title }}</th>-->
<!--                        </template>-->
<!--                    </tr>-->
<!--                    </thead>-->
<!--                    <tbody :style="{ backgroundColor: tbodyBackgroundColor }">-->
<!--                    <tr>-->
<!--                        <template v-for="item in getTableShorInfo">-->
<!--                            <template v-if="item.content">-->
<!--                                <td>-->
<!--                                    <template v-if="item?.tooltip">-->
<!--                                        <template v-if="item.tooltip?.text">-->
<!--                                            <div class="overflow-text-content position-relative" style="width: 110px">-->
<!--                                                <div v-tooltip="item.tooltip" class="position-absolute" style="width: 100%; inset: 0"></div>-->
<!--                                                <span>{{ item.content }}</span>-->

<!--                                            </div>-->
<!--                                        </template>-->
<!--                                        <template v-else>-->
<!--                                            <span v-if="item.tooltip?.list" v-tooltip="item.tooltip">{{ item.content }}</span>-->
<!--                                            <span v-else>{{ item.content }} {{ item.expired ? 'dsad' : 'ds' }}</span>-->
<!--                                            <v-icon v-if="item.icon">{{item.icon}}</v-icon>-->
<!--                                        </template>-->
<!--                                    </template>-->
<!--                                    <template v-else>-->
<!--                  <span v-if="!item?.promo" :class="item?.class" :style="item.expired && 'text-decoration: line-through solid pink;'">-->
<!--                    {{ item.content }}-->
<!--                        <v-icon v-if="item.icon" @click="item.iconFoo">{{item.icon}}</v-icon>-->
<!--                    <template v-if="item.expired">-->
<!--                      <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="-5.0 -10.0 110.0 135.0">-->
<!--                        <path d="m96.102 76.898-37.102-64.199c-4-7-14-7-18.102 0l-37 64.199c-4 6.8984 1 15.602 9 15.602h74.102c8.1016 0 13.102-8.6992 9.1016-15.602zm-46.102-48.199c3.1992 0 5.6992 2.6016 5.6992 5.6992v0.39844l-1.8984 28.602c-0.10156 2-1.8008 3.6016-3.8008 3.6016s-3.6992-1.6016-3.8008-3.6016l-1.8984-28.602c-0.19922-3.2969 2.3984-6.0977 5.6992-6.0977zm0 53.402c-3.3008 0-6-2.6992-6-6 0-3.3008 2.6992-6 6-6s6 2.6992 6 6c0 3.2969-2.6992 6-6 6z" fill="#EA580C"/>-->
<!--                      </svg>-->
<!--                    </template>-->
<!--                  </span>-->
<!--                                        <span v-if="item?.promo" :class="item?.class"><i class="fa fa-coins"></i> {{ item.content }}</span>-->
<!--                                    </template>-->
<!--                                </td>-->
<!--                            </template>-->
<!--                        </template>-->
<!--                    </tr>-->
<!--                    </tbody>-->
<!--                </table>-->

                <table class="pq-table1 first-and-top w-100 align-self-start">
                    <thead>
                    <tr class="bg-gray-lighter">
                        <th style="padding: 0 14px;;" class="text-uppercase">STATUS</th>
                        <th  style="padding: 0 14px;"  class="text-uppercase">PQ ID</th>
                        <th style="padding: 0 14px; white-space: nowrap;" class="text-uppercase">PQ CREATOR</th>
                        <th style="padding: 0 14px; white-space: nowrap;">
                            PQ OWNER
                        </th>
                        <th style="padding: 0 14px; white-space: nowrap;" class="text-uppercase">Remark Int.</th>
                        <th style="padding: 0 14px; white-space: nowrap;" class="text-uppercase">Remark Ext.</th>
                        <th style="padding: 0 14px; white-space: nowrap;" class="text-uppercase">CREATED</th>
                        <th  style="padding: 0 14px; white-space: nowrap;" class="text-uppercase">Sent
                            <svg v-if="props.data.send_logs.length > 0" width="19" height="19" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="VIP_bgCarrier" stroke-width="0"></g>
                            <g id="VIP_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                            <g id="VIP_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM16.0303 8.96967C16.3232 9.26256 16.3232 9.73744 16.0303 10.0303L11.0303 15.0303C10.7374 15.3232 10.2626 15.3232 9.96967 15.0303L7.96967 13.0303C7.67678 12.7374 7.67678 12.2626 7.96967 11.9697C8.26256 11.6768 8.73744 11.6768 9.03033 11.9697L10.5 13.4393L12.7348 11.2045L14.9697 8.96967C15.2626 8.67678 15.7374 8.67678 16.0303 8.96967Z" fill="#4CAF51"></path> </g>
                        </svg>
                        </th>
                        <th style="padding: 0 14px;; white-space: nowrap;" class="text-uppercase">Opened
                            <svg v-if="props.data.trackPqs.length > 0" width="19" height="19" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="VIP_bgCarrier" stroke-width="0"></g>
                                <g id="VIP_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="VIP_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM16.0303 8.96967C16.3232 9.26256 16.3232 9.73744 16.0303 10.0303L11.0303 15.0303C10.7374 15.3232 10.2626 15.3232 9.96967 15.0303L7.96967 13.0303C7.67678 12.7374 7.67678 12.2626 7.96967 11.9697C8.26256 11.6768 8.73744 11.6768 9.03033 11.9697L10.5 13.4393L12.7348 11.2045L14.9697 8.96967C15.2626 8.67678 15.7374 8.67678 16.0303 8.96967Z" fill="#4CAF51"></path> </g>
                            </svg>
                        </th>
                        <th style="padding: 0 14px;; white-space: nowrap;" class="text-uppercase">OAF OPENED
                            <svg v-if="props.data.oaf_opens.length > 0" width="19" height="19" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="VIP_bgCarrier" stroke-width="0"></g>
                                <g id="VIP_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="VIP_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM16.0303 8.96967C16.3232 9.26256 16.3232 9.73744 16.0303 10.0303L11.0303 15.0303C10.7374 15.3232 10.2626 15.3232 9.96967 15.0303L7.96967 13.0303C7.67678 12.7374 7.67678 12.2626 7.96967 11.9697C8.26256 11.6768 8.73744 11.6768 9.03033 11.9697L10.5 13.4393L12.7348 11.2045L14.9697 8.96967C15.2626 8.67678 15.7374 8.67678 16.0303 8.96967Z" fill="#4CAF51"></path> </g>
                            </svg>
                        </th>
                    </tr>
                    </thead>
                    <tbody :style="{ backgroundColor: tbodyBackgroundColor }">
                    <tr>
                        <!-- STATUS -->
                        <td style="padding: 0 14px;;">
        <span :style="props.data.expired && 'text-decoration: line-through solid pink;'">
          {{ props.data.status }}
        </span>
                        </td>
                        <td style="padding: 0 14px;; white-space: nowrap;">
                            <span>{{ props.data.hash_id ?? props.data.id }}</span>
                        <v-icon size="14" class="ml-1" @click="copyToClipboard(props.data.hash_id ?? props.data.id)">mdi-content-copy</v-icon>
                        </td>
                        <!-- CREATED BY -->
                        <td style="padding: 0 14px;;">
                            <span>{{ props.data.created_by.split(' ')[0] ?? '' }}</span>
                        </td>
                        <td style="padding: 0 14px;;">
                            {{props.data.sent_as ?? ''}}
                        </td>
                        <!-- Remark Int. -->
                        <td>
                            <div @click="copyToClipboard(props.data.remark_int)"  v-if="props.data.remark_int" v-tooltip="{ text: props.data.remark_int }" class="overflow-text-content position-relative">
                                <v-icon>mdi-comment-text-outline</v-icon>
                            </div>
                            <span v-else>-</span>
                        </td>

                        <!-- Remark Ext. -->
                        <td>
                            <div @click="copyToClipboard(props.data.remark_ext)" v-if="props.data.remark_ext"  v-tooltip="{ text: props.data.remark_ext }" class="overflow-text-content position-relative">
                                <v-icon class="mr-1">mdi-comment-text-outline</v-icon>
                            </div>
                            <span v-else>-</span>
                        </td>
                        <!-- CREATED -->
                        <td style="padding: 0 14px;" v-tooltip="formatDateToDDMMMYYWithTimeTooltip(props.data.created)">
                            <span >{{ formatDateToDDMMMYYWithTime(props.data.created) }}</span>
                        </td>

                        <td v-tooltip="tooltipSendLogsDate">
                            <div class="ticket-icon-wrapper">
                                <span >{{ displaySendLogsDate  }}</span>
                                <span v-if="props.data.send_logs.length > 1" class="number-circle-one">{{ props.data.send_logs.length}}</span>
                            </div>

                        </td>
                        <td v-tooltip="trackPqsTooltipText">
                            <div class="ticket-icon-wrapper">
                            <span >{{ displayTrackPqsDate }}</span>
                                <span v-if="props.data.trackPqs.length > 1" class="number-circle-one">{{ props.data.trackPqs.length}}</span>
                            </div>
                        </td>
                        <td v-tooltip="formatFormOpens">
                            <div class="ticket-icon-wrapper">
                            <span >{{ displayOafOpensDate }}</span>
                                <span v-if="props.data.oaf_opens.length > 1" class="number-circle-one">{{ props.data.oaf_opens.length}}</span>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="pq-tables-partials  d-flex gap-3 ">
            <div class="pq-table-item pq-table-mobile">
                <table class="pq-table first align-self-start ">

                    <tbody>
                    <tr>
                        <td>STATUS</td>
                        <td style="padding: 0 14px;;">
        <span :style="props.data.expired && 'text-decoration: line-through solid pink;'">
          {{ props.data.status }}
        </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            PQ ID
                        </td>
                        <td style="padding: 0 14px;; white-space: nowrap;">
                            <span>{{ props.data.hash_id ?? props.data.id }}</span>
                            <v-icon size="14" class="ml-1" @click="copyToClipboard(props.data.hash_id ?? props.data.id)">mdi-content-copy</v-icon>
                        </td>
                    </tr>
                    <tr>
                        <td>CREATED</td>
                        <td style="padding: 0 14px;;">
                            <span v-tooltip="formatDateToDDMMMYYWithTimeTooltip(props.data.created)">{{ formatDateToDDMMMYYWithTime(props.data.created) }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>PQ CREATOR</td>
                        <td style="padding: 0 14px;;">
                            <span>{{ props.data.created_by.split(' ')[0] ?? '' }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>PQ OWNER</td>
                        <td style="padding: 0 14px;;">
                            {{props.data.sent_as ?? ''}}
                        </td>
                    </tr>
                    <tr>
                        <td>REMARK INT.</td>
                        <td>
                            <div v-if="props.data.remark_int" v-tooltip="{ text: props.data.remark_int }" class="overflow-text-content position-relative">
                                <v-icon>mdi-comment-text-outline</v-icon>
                            </div>
                            <span v-else>-</span>
                        </td>
                    </tr>
                    <tr>
                        <td>REMARK EXT.</td>
                        <td>
                            <div v-if="props.data.remark_ext"  v-tooltip="{ text: props.data.remark_ext }" class="overflow-text-content position-relative">
                                <v-icon class="mr-1">mdi-comment-text-outline</v-icon>
                            </div>
                            <span v-else>-</span>
                        </td>
                    </tr>
                    <tr>
                        <td>SENT
                            <svg v-if="props.data.send_logs.length > 1" width="19" height="19" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="VIP_bgCarrier" stroke-width="0"></g>
                                <g id="VIP_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="VIP_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM16.0303 8.96967C16.3232 9.26256 16.3232 9.73744 16.0303 10.0303L11.0303 15.0303C10.7374 15.3232 10.2626 15.3232 9.96967 15.0303L7.96967 13.0303C7.67678 12.7374 7.67678 12.2626 7.96967 11.9697C8.26256 11.6768 8.73744 11.6768 9.03033 11.9697L10.5 13.4393L12.7348 11.2045L14.9697 8.96967C15.2626 8.67678 15.7374 8.67678 16.0303 8.96967Z" fill="#4CAF51"></path> </g>
                            </svg>
                        </td>
                        <td>
                            <span v-tooltip="tooltipSendLogsDate">{{ displaySendLogsDate  }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>OPENED
                            <svg v-if="props.data.trackPqs.length > 1" width="19" height="19" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="VIP_bgCarrier" stroke-width="0"></g>
                                <g id="VIP_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="VIP_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM16.0303 8.96967C16.3232 9.26256 16.3232 9.73744 16.0303 10.0303L11.0303 15.0303C10.7374 15.3232 10.2626 15.3232 9.96967 15.0303L7.96967 13.0303C7.67678 12.7374 7.67678 12.2626 7.96967 11.9697C8.26256 11.6768 8.73744 11.6768 9.03033 11.9697L10.5 13.4393L12.7348 11.2045L14.9697 8.96967C15.2626 8.67678 15.7374 8.67678 16.0303 8.96967Z" fill="#4CAF51"></path> </g>
                            </svg>
                        </td>
                        <td>
                            <span v-tooltip="tooltipTrackPqsDate">{{ displayTrackPqsDate }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>OAF OPENED
                            <svg v-if="props.data.oaf_opens.length > 1" width="19" height="19" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="VIP_bgCarrier" stroke-width="0"></g>
                                <g id="VIP_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="VIP_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM16.0303 8.96967C16.3232 9.26256 16.3232 9.73744 16.0303 10.0303L11.0303 15.0303C10.7374 15.3232 10.2626 15.3232 9.96967 15.0303L7.96967 13.0303C7.67678 12.7374 7.67678 12.2626 7.96967 11.9697C8.26256 11.6768 8.73744 11.6768 9.03033 11.9697L10.5 13.4393L12.7348 11.2045L14.9697 8.96967C15.2626 8.67678 15.7374 8.67678 16.0303 8.96967Z" fill="#4CAF51"></path> </g>
                            </svg>
                        </td>
                        <td>
                            <span v-tooltip="tooltipOafOpensDate">{{ displayOafOpensDate }}</span>
                        </td>
                    </tr>
<!--                    <template v-for="item in getTableShorInfo">-->
<!--                        <tr v-if="item.content">-->
<!--                            <td class="text-uppercase" :class="item?.class">{{ item.title }}</td>-->
<!--                            <td>-->
<!--                                <template v-if="item?.tooltip">-->

<!--                                    <template v-if="item.tooltip?.text">-->

<!--                                        <div class="overflow-text-content position-relative" style="width: 110px">-->
<!--                                            <div v-tooltip="item.tooltip" class="position-absolute"-->
<!--                                                 style="width: 100%; inset: 0"></div>-->
<!--                                            <span>{{ item.content }}</span>-->
<!--                                        </div>-->
<!--                                    </template>-->
<!--                                    <template v-else>-->
<!--                                        <span v-if="item.tooltip?.list"  v-tooltip="item.tooltip">{{ item.content }}</span>-->
<!--                                        <span v-else >{{ item.content }}</span>-->
<!--                                    </template>-->

<!--                                </template>-->
<!--                                <template v-else>{{ item.content }}</template>-->

<!--                            </td>-->
<!--                        </tr>-->
<!--                    </template>-->
                    </tbody>
                </table>
            </div>
            <div class="pq-table-item d-flex flex-shrink-0" v-if="getTablePassengerPrice.show">
                <table class="pq-table second align-self-start desktop-pq-table">
                    <tbody>
                    <tr v-for="item in getTablePassengerPrice.table"
                        :style="{ backgroundColor:item?.bgColor}">
                        <td class="text-uppercase" style="width: 100px">{{ item.title }}</td>
                        <template v-if="Array.isArray(item.content)">
                            <td v-for="tdItem in item.content">
                                <template v-if="tdItem?.icon">
                                    <div class="d-flex align-items-center white-space-nowrap">
                                        <acms-v-icon size="20" :name="tdItem.icon"/>
                                        x {{ tdItem.count }}
                                    </div>
                                </template>
                                <template v-else>

                                    <div class="white-space-nowrap">
                                        {{ tdItem }}
                                    </div>
                                </template>
                            </td>
                        </template>
                        <td v-else :colspan="item?.colspan" :class="item?.class">{{ item.content }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="pq-table-item pq-table-block  w-100  d-flex flex-column h-100">
                <PqCodeTable class="mb-4"
                             v-if="data?.pq_flight_legs?.length > 0"
                             :data="{pq_flight_legs:data.pq_flight_legs, flight_segment_routes:data.flight_segment_routes}"
                             :simple-view="simpleView"
                             tableClass="table-border-td w-100"
                             showFromBack
                             :pccOrProgram="data.pcc_or_program == 'Mixed'"
                />
                <div class="d-flex  mt-auto align-items-center ">
                    <div class="d-flex ms-auto gap-2">
<!--                        <template v-if="showOpenedIcon">-->
<!--                            <span v-tooltip="formatFormOpens">-->
<!--                                 <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#48752C"><path d="M80-160v-640h800v640H80Zm80-80h640v-480H160v480Zm0 0v-480 480Zm160-40h80v-40h40q17 0 28.5-11.5T480-360v-120q0-17-11.5-28.5T440-520H320v-40h160v-80h-80v-40h-80v40h-40q-17 0-28.5 11.5T240-600v120q0 17 11.5 28.5T280-440h120v40H240v80h80v40Zm320-30 80-80H560l80 80Zm-80-250h160l-80-80-80 80Z"/></svg>-->
<!--                            </span>-->
<!--                        </template>-->
<!--                        <template v-if="trackPqsTooltipText === 'not sent'">-->
<!--                        </template>-->
<!--                        <template v-else-if="trackPqsTooltipText === 'sent'">-->
<!--                            <svg style="margin-right: 3px" xmlns="http://www.w3.org/2000/svg" width="22" height="24" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#666666" class="size-6">-->
<!--                                <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />-->
<!--                            </svg>-->
<!--                        </template>-->
<!--                        <template v-else>-->
<!--                            <span style="padding: 0; margin: 0" v-tooltip.left="trackPqsTooltipText">-->
<!--                                 <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#4CAF51" class="size-6">-->
<!--                                <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 9v.906a2.25 2.25 0 0 1-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 0 0 1.183 1.981l6.478 3.488m8.839 2.51-4.66-2.51m0 0-1.023-.55a2.25 2.25 0 0 0-2.134 0l-1.022.55m0 0-4.661 2.51m16.5 1.615a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V8.844a2.25 2.25 0 0 1 1.183-1.981l7.5-4.039a2.25 2.25 0 0 1 2.134 0l7.5 4.039a2.25 2.25 0 0 1 1.183 1.98V19.5Z" />-->
<!--                            </svg>-->
<!--                            </span>-->
<!--                        </template>-->
                        <acms-v-icon size="24" name="time" v-if="data?.show_fast_time" color="#4B5563CC"/>
                        <acms-v-icon size="24" name="money" v-if="data?.show_min_money" color="#4B5563CC"/>

                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>export default {name: 'PartialPriceQuotesTables'};</script>
<script setup="">
import {useAlertsManagerStore} from "@store/plugins/alerts-manager";
import PqCodeTable from './PartialParsedPqCodeTable.vue';
import {computed, onMounted} from "vue";
import {useBackendMiddlewarePropsService} from "@services";
import {parse} from "date-fns";
const props = defineProps({
    data: Object,
    simpleView: Boolean
})
// onMounted(() => {
// });

const findEarliestDate = (datesArray) => {
    if (!datesArray || datesArray.length === 0) return null;
    return datesArray.reduce((earliest, current) => {
        const currentDate = new Date(current);
        return !earliest || currentDate < earliest ? currentDate : earliest;
    }, null);
};

// Вычисляем ранние даты для каждого набора данных
const earliestSendLogsDate = computed(() =>
    findEarliestDate(props.data.send_logs?.map((log) => log.send_at))
);
const earliestTrackPqsDate = computed(() =>
    findEarliestDate(props.data.trackPqs?.map((pq) => pq.created_at))
);
const earliestOafOpensDate = computed(() =>
    findEarliestDate(props.data.oaf_opens?.map((open) => open.created_at))
);

// Форматирование даты для отображения в компоненте ("15JAN24")
const formatForDisplay = (date) => {
    if (!date) return '-';
    const options = { day: '2-digit', month: 'short', year: '2-digit' };
    const formatter = new Intl.DateTimeFormat('en-US', options);
    const parts = formatter.formatToParts(date);
    const day = parts.find((part) => part.type === 'day')?.value;
    const month = parts.find((part) => part.type === 'month')?.value.toUpperCase();
    const year = parts.find((part) => part.type === 'year')?.value;
    return `${day}${month}${year}`;
};

// Форматирование для tooltip (полный формат "12/26/2024, 12:08")
const formatForTooltip = (date) => {
    if (!date) return '-';
    const optionsDate = { day: '2-digit', month: 'short', year: '2-digit' };
    const optionsTime = { hour: '2-digit', minute: '2-digit', hour12: false };

    const formatterDate = new Intl.DateTimeFormat('en-US', optionsDate);
    const formatterTime = new Intl.DateTimeFormat('en-US', optionsTime);

    const formattedDate = formatterDate.formatToParts(date);
    const day = formattedDate.find((part) => part.type === 'day')?.value;
    const month = formattedDate.find((part) => part.type === 'month')?.value.toUpperCase();
    const year = formattedDate.find((part) => part.type === 'year')?.value;

    const time = formatterTime.format(date);
    return `Created on ${day}${month}${year} at ${time}`;
};
const formatForTooltipSent = () => {
    if (!props.data.send_logs || props.data.send_logs.length === 0) {
        return "No logs available";
    }

    // Используем уже готовое поле message
    return props.data.send_logs
        .map(log => log.message) // Берем поле message из каждого элемента
        .join('\n'); // Соединяем их в одну строку с разделением переносами строк
};



// Вывод данных для send_logs
const displaySendLogsDate = computed(() =>
    formatForDisplay(earliestSendLogsDate.value)
);
const tooltipSendLogsDate = computed(() =>
    formatForTooltipSent(earliestSendLogsDate.value)
);

// Вывод данных для trackPqs
const displayTrackPqsDate = computed(() =>
    formatForDisplay(earliestTrackPqsDate.value)
);
const tooltipTrackPqsDate = computed(() =>
    formatForTooltip(earliestTrackPqsDate.value)
);

// Вывод данных для oaf_opens
const displayOafOpensDate = computed(() =>
    formatForDisplay(earliestOafOpensDate.value)
);
const tooltipOafOpensDate = computed(() =>
    formatForTooltip(earliestOafOpensDate.value)
);

const AlertsManager = useAlertsManagerStore();
const showOpenedIcon = computed(()=>{
    return props.data?.opened;
})

const formatFormOpens = computed(() => {
    const { oaf_opens = [], send_logs } = props.data || [];
    const now = new Date();

    if (oaf_opens.length === 0) {
        return 'No form opens recorded.';
    }

    // Определяем дату отправки для расчёта времени с момента отправки до первого открытия
    const firstSentDate = oaf_opens.length > 0 ? new Date(oaf_opens[oaf_opens.length - 1].created_at) : null;

    // Функция для расчёта времени между двумя датами
    function formatTimeDifferenceBetweenDates(startDate, endDate) {
        const diff = Math.abs(endDate - startDate);
        const minutes = Math.floor(diff / (1000 * 60));
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        if (days > 0) {
            return `${days}d ${hours % 24}h ${minutes % 60}m`;
        } else if (hours > 0) {
            return `${hours}h ${minutes % 60}m`;
        } else {
            return `${minutes}m`;
        }
    }

    // Формируем строки для каждого открытия формы
    const messages = oaf_opens.map((open, index) => {
        const openDate = new Date(open.created_at);

        // Форматируем дату и время открытия
        const formattedDate = `${openDate.getDate().toString().padStart(2, '0')}${openDate.toLocaleString('en', { month: 'short' }).toUpperCase()}${openDate.getFullYear().toString().slice(-2)}`;
        const formattedTime = openDate.toLocaleTimeString('en-GB', {
            hour: '2-digit',
            minute: '2-digit'
        });

        // Рассчитываем время, прошедшее с момента открытия
        const timeAgo = formatTimeDifferenceBetweenDates(openDate, now);

        // Определяем местоположение и тип устройства
        const location = `${open.locationCity}`;
        const browser = `${open.locationState}`;
        // console.log('test', browser);
        const deviceType = open.deviceType;

        // Если это первое открытие, добавляем информацию о времени после отправки
        if (index === 0 && firstSentDate) {
            const timeSinceSent = formatTimeDifferenceBetweenDates(firstSentDate, openDate);
            return `${formattedDate} ${formattedTime}. First open ${timeSinceSent} after it was sent. Location: ${location}. Browser: ${browser}. Device: ${deviceType}.`;
        } else {
            return `${formattedDate} ${formattedTime}. Opened ${timeAgo} ago. Location: ${location}. Browser: ${browser}. Device: ${deviceType}.`;
        }
    });

    return messages.reverse().join('\n');
});

const trackPqsTooltipText = computed(() => {
    const created_by = props.data?.created_by;
    const trackPqs = props.data?.trackPqs || [];

    if (props.data.send_logs.length == 0) {
        return 'not sent';
    }

    if (trackPqs.length === 0 && props.data.send_logs.length > 0) {
       return 'sent'; // Если массив пуст, тултип не нужен
    }
    const now = new Date();
    const pq_sent = new Date(props.data.send_logs[props.data.send_logs.length-1].send_at);
    // Функция для расчёта времени, прошедшего с момента события до текущего момента
    function formatTimeDifference(date) {
        const diff = Math.abs(now - date);
        const minutes = Math.floor(diff / (1000 * 60));
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        if (days > 0) {
            return `${days}d ago`;
        } else if (hours > 0) {
            const remainingMinutes = minutes % 60;
            return `${hours}h ${remainingMinutes}m ago`;
        } else {
            return `${minutes}m ago`;
        }
    }

    // Функция для расчёта времени между отправкой и первым открытием
    function formatTimeDifferenceBetweenDates(startDate, endDate) {

        const diff = Math.abs(endDate - startDate);
        const minutes = Math.floor(diff / (1000 * 60));
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        if (days > 0) {
            return `${days}d ${hours % 24}h ${minutes % 60}m`;
        } else if (hours > 0) {
            return `${hours}h ${minutes % 60}m`;
        } else {
            return `${minutes}m`;
        }

    }

    // Сортировка событий по дате (от самого нового к самому старому)
    const sortedTrackPqs = trackPqs.slice().sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

    // Первое событие для отображения информации об открытии
    const firstTrackPq = sortedTrackPqs[sortedTrackPqs.length - 1];
    const firstCreatedAt = firstTrackPq ? new Date(firstTrackPq.created_at) : null;
    let firstOpenInfo = '';

    if (firstCreatedAt) {
        const firstOpenTime = formatTimeDifferenceBetweenDates(firstCreatedAt, pq_sent);
        firstOpenInfo = `First open ${firstOpenTime} after it was sent.`;
    }

    // Форматируем строки для всех событий, начиная с самого свежего
    const messages = sortedTrackPqs.map((pq, index) => {
        const createdAt = new Date(pq.created_at);

        // Форматируем дату и время создания события
        const formattedDate = `${createdAt.getDate().toString().padStart(2, '0')}${createdAt.toLocaleString('en', { month: 'short' }).toUpperCase()}${createdAt.getFullYear().toString().slice(-2)}`;
        const formattedTime = createdAt.toLocaleTimeString('en-GB', {
            hour: '2-digit',
            minute: '2-digit'
        });

        // Рассчитываем время, прошедшее с момента создания события
        const timeAgo = formatTimeDifference(createdAt);

        return `${sortedTrackPqs.length - index}) ${formattedDate}, ${formattedTime} (${timeAgo})`;
    });

    // Добавляем информацию о первом открытии
    messages.push(firstOpenInfo);

    // Добавляем информацию об отправке
    const firstSentInfo = firstTrackPq ?
        `→ ${firstCreatedAt.getDate().toString().padStart(2, '0')}${firstCreatedAt.toLocaleString('en', { month: 'short' }).toUpperCase()}${firstCreatedAt.getFullYear().toString().slice(-2)}, ${firstCreatedAt.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })} sent by ${created_by ?? 'Creator'}`
        : '';

    messages.push(firstSentInfo);
    return messages.join('\n');
});

const tbodyBackgroundColor = computed(() => {

    const status = props.data.status;

    switch (status) {
        case 'Sent':
            return '#E1EDCF';
        case 'Re-Sent':
            return '#E1EDCF';
        case 'OAF ↓':
            return '#f5e99a';
        case 'Sold':
            return '#7dc680';
        case 'Rejected':
            return '#ddbcd7';
        case 'Declined':
            return '#ddbcd7';
        default:
            return '#FFF'; // Цвет по умолчанию, если статус не найден
    }
});

const {backendOptions} = useBackendMiddlewarePropsService()

const formatDateToDDMMMYYWithTime = (dateTimeStr) => {
    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    // Разбиваем исходную строку на дату и время
    const [datePart, timePart] = dateTimeStr.split(', ');
    const [month, day , year] = datePart.split('/').map(num => parseInt(num, 10));
    const yearShort = year.toString().substr(-2);
    const monthShort = months[month - 1];
    return `${day < 10 ? '0' + day : day}${monthShort}${yearShort}`;
};
const formatDateToDDMMMYYWithTimeTooltip = (dateTimeStr) => {
    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

    // Разбиваем исходную строку на дату и время
    const [datePart, timePart] = dateTimeStr.split(', ');
    const [month, day, year] = datePart.split('/').map(num => parseInt(num, 10));
    const yearShort = year.toString().substr(-2);
    const monthShort = months[month - 1];

    // Форматируем день с ведущим нулём, если он меньше 10
    const formattedDay = day < 10 ? '0' + day : day;

    // Возвращаем результат в нужном формате
    return `Created ${formattedDay}${monthShort}${yearShort} at ${timePart}`;
};
// Вывод: 26DEC24 at 12:08

function fallbackCopyToClipboard(text) {
    const textArea = document.createElement('textarea');
    textArea.value = text;

    // Скрываем элемент из области видимости
    textArea.style.position = 'fixed';
    textArea.style.left = '-9999px';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        document.execCommand('copy');
        console.log('Текст скопирован (fallback):', text);
    } catch (err) {
        console.error('Ошибка при fallback копировании:', err);
    }

    document.body.removeChild(textArea);
}
const copyToClipboard = (text) => {
    if (!navigator.clipboard) {
        fallbackCopyToClipboard(text); // Используем fallback для старых браузеров
        return;
    }
    navigator.clipboard
        .writeText(text)
        .then(() => {
            AlertsManager.add('Successfully copied')
            console.log('Текст скопирован в буфер обмена:', text);
        })
        .catch((err) => {
            console.error('Ошибка при копировании в буфер обмена:', err);
        });
}
const getTableShorInfo = computed(() => {

    const {id, hash_id, remark_ext, remark_int, status, created_by, expired, created, role, pcc_or_program, send_logs, is_promo } = props.data


    return [
        {title: 'STATUS', content: status, style: 'width: 8%', expired: expired},
        {title: 'PQ ID', content: hash_id ?? id, style: 'width: 8%', icon: 'mdi-content-copy'},
        {title: 'CREATED', content: formatDateToDDMMMYYWithTime(created)},
        // {title: 'CREATED', content: created},
        {title: 'CREATED BY', content: created_by},
        {title: 'ROLE', content: role?.name ?? 'error role'},
        {
            title: 'Remark Int.',
            content: remark_int ?? 'No remarks',
            tooltip: {text: remark_int ?? ''}
        },
        {
            title: 'Remark Ext.',
            content: remark_ext ?? 'No remarks',
            tooltip: {text:remark_ext ?? ''}
        },
        {title: 'PCC/Program', content: pcc_or_program, style:'width: 15%',  class: is_promo ? 'promo-super-saver promo-button' : '', promo : is_promo},
        {title: 'Sent', content: send_logs?.[0]?.send_at,
        tooltip :send_logs?.length > 1 ?  {list:send_logs.map(i=>i?.send_at)}  : null,

        },
    ]
})
const getTablePassengerPrice = computed(() => {
    const {pq_passengers} = props.data

    const {adults, children = null, infants = null, profit = null, total = null, sell, total_net} = pq_passengers
    const passengersEntries = Object.entries({adults, children, infants})

    const ptcArr = []
    const netObject = {}
    const saleArr = []
    const totalNetArr = []

    passengersEntries.forEach(([itemKey, itemValue]) => {
        // console.log(sell, total_net);
        if (itemValue) {
            ptcArr.push({icon: itemKey, count: itemValue?.[0]?.quantity ?? '0'})
            itemValue.forEach((val, index) => {
                if (!netObject[index + 1]) {
                    netObject[index + 1] = []
                }
                netObject[index + 1].push('$' + val?.net ?? '0')
            })
            saleArr.push('$' + sell[itemKey].price ?? '0')
            totalNetArr.push('$' + total_net[itemKey].price ?? '0')
        }
    }, [])

    const totalAndProfitClass = 'fw-semi-bold' + (ptcArr?.length == 1 ? '' : ' text-center')
    const colspan = ptcArr?.length
    const netArr = Object.entries(netObject)
        .map(([keyNumber, valueArr]) => ({title: 'NET #' + keyNumber, content: valueArr}))
    return {
        count: ptcArr?.length,
        show: ptcArr?.length > 0,
        table: [
            {title: 'PTC', content: ptcArr},
            ...netArr,
            {title: 'TOTAL NET', bgColor: '#FBDECE', content: totalNetArr, colspan, class: totalAndProfitClass},
            {title: 'SALE', content: saleArr},
            {
                title: 'SELLING TOTAL',
                bgColor: '#FBDECE',
                content: total ? '$' + total : null,
                colspan,
                class: totalAndProfitClass
            },
            {
                title: 'MARK-UP',
                bgColor: '#E0EDCF',
                content: profit ? '$' + profit : null,
                colspan,
                class: totalAndProfitClass
            },
        ]
    }
})
</script>
<style scoped lang="scss">


.pq-tables1-item-content-inner {
    width: 100%;
}

.pq-table1 {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
    border: 1px solid #ddd;
    margin-bottom: 15px;
}

.pq-table1 th,
.pq-table1 td {
    border: 1px solid #ddd;
    padding: 2px 4px;
    text-align: center;
    line-height: 1.1;
    font-size: 12px;
    color: black;
}

.pq-table1 th {
    background-color: #f4f4f4;
    font-weight: bold;
}
.pq-table1 tr {
    height: 30px;
}
.pq-table1 tr:nth-child(even) {
    background-color: #f9f9f9;
}

.overflow-text-content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@media only screen and (max-width: 1430px) {
    .pq-tables-item-content-inner {
        width: 94%;
    }
}


.pq-table-mobile {
    display: none;
}

@media only screen and (max-width: 1500px) {
    .pq-table-mobile {
        display: block;
    }
    .pq-table-desktop {
        display: none;
    }

    .pq-tables-partials {
        flex-wrap: wrap;
    }
    .pq-table-item {
        &:last-child {
            width: 100%;
        }
    }
}

.promo-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 12px; /* Размер шрифта */
    height: 28px; /* Высота кнопки */
    padding: 0 10px; /* Внутренние отступы */
    color: #fff; /* Цвет текста */
    cursor: pointer;
    margin: 5px;
    border: none;
    border-radius: 5px; /* Закругленные углы */
    font-family: Arial, Helvetica, sans-serif;
    transition: background-color 0.3s, transform 0.2s, box-shadow 0.3s;
}

/* Эффект при наведении */
.promo-button:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

/* Иконка слева */
.promo-button .fa {
    margin-right: 6px;
    font-size: 14px; /* Размер иконки */
    color: #fff; /* Белый цвет иконок */
}

/* Кнопка SUPER SAVER с иконкой монеток и фиолетовым фоном */
.promo-super-saver {
    background-color: #9C27B0; /* Фиолетовый */
}


.ticket-icon-wrapper {
    position: relative; /* Для позиционирования кружка с номером относительно буквы */
    display: inline-flex; /* Располагает элементы горизонтально */
    align-items: center;
    justify-content: center;
}

.number-circle-one {
    position: absolute;
    top: -5px;
    right: -15px;
    background-color: #dc6535;
    color: #FFFFFF;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: 600;
    line-height: 1;
}
</style>
