<template>
    <div class="box-price-quotes w-100">
<!--        <template v-if="locState.canShowHeadButtons">-->
<!--            <teleport to="#request-page-head-tab-pq">-->
<!--                <div class="d-flex">-->
<!--                    <ElementDropdownAddPq v-if="backendPermissions?.allowCreateRequestPQ" />-->
<!--                    <ElementSendPqMulti v-if="backendPermissions?.allowSendRequestPQ"-->
<!--                        :loading="locState.emailModalOpeningMulti == 'header'"-->
<!--                        :emails-data="getSendEmails"-->
<!--                        @click="()=>showSendEmailModal(null, 'header')"-->
<!--                    />-->
<!--                </div>-->
<!--            </teleport>-->
<!--        </template>-->
        <PartialTabsCurrentSelectedRequest
            :requests="requests"
            :currentActiveRequestTab="currentActiveRequestTab"
            @changeActiveRequestTab="$emit('changeActiveRequestTab', $event)"
            :pqs="pqs">
            <template #nav-right>
<!--                <div style="display: flex; flex-direction: column; justify-content: center; align-items: center">-->
                    <div class="pq-flights-actions d-flex align-items-center ms-auto gap-2 ">
                        <ElementDropdownAddPq v-if="backendPermissions?.allowCreateRequestPQ"/>
                        <ElementSendPqMulti  v-if="backendPermissions?.allowSendRequestPQ"
                                             :loading="locState.emailModalOpeningMulti === 'tabs'"
                                             :emails-data="getSendEmails"
                                             @click="()=>showSendEmailModal(null, 'tabs')"
                        />
                        <v-btn
                            height="30"
                            color="surface-variant"
                            text="SEND SMS"
                            variant="flat"
                            @click="openDialog"
                        ></v-btn>
                        <SmsDialog  v-model="dialogOpen" :selected-pqs="locState.pqRowsChecked"/>
                        <ElementDropdownNextActions/>
                        <v-btn v-if="showTakeButton" @click="assignSelf">Take</v-btn>
                    </div>
<!--                    <div>-->
<!--                        <v-btn>Take</v-btn>-->
<!--                    </div>-->
<!--                </div>-->
</template>
        </PartialTabsCurrentSelectedRequest>
        <div class="pt-4 justify-content-center d-flex" v-if="currentActiveRequestPqs?.length  == 0">
<!--            <ElementDropdownAddPq v-if="backendPermissions?.allowCreateRequestPQ"/>-->
        </div>
        <acms-v-tabs v-else
                     class="pq-manager"
                     v-bind="PQ_MANAGER_TABS_CONFIG"
                     :nav-quantity="{
                            'pqs' : currentActiveRequestAgentPqsArray?.length ?? 0,
                            'all-pqs' : currentActiveRequestPqs?.length ?? 0,
                            'hidden-pqs' : '0',
                            'tickets' : 3,
                       }"
        >
            <template #nav-right>
                <div class="ms-auto d-flex flex-wrap">
<!--                    <div class="pq-filter-select  me-3">-->
<!--                        <acms-v-select-->
<!--                            placeholder="Filter by"-->
<!--                            v-model="locState.filterPqsBy"-->
<!--                            multi-->
<!--                            :options="[-->
<!--                                {id:1, title: 'Hidden', quantity: getFilterOptionQnt.hidden.toString()},-->
<!--                                {id:2, title: 'Sent', quantity: getFilterOptionQnt.sent.toString()},-->
<!--                                // {id:3, title: 'Supervisor', quantity: '2'},-->
<!--                                // {id:4, title: 'Expert', quantity: '2'},-->
<!--                            ]"-->
<!--                            :toggle-key-title="()=>'Filter by'"-->
<!--                        >-->
<!--                        </acms-v-select>-->
<!--                    </div>-->

                    <v-chip-group
                        v-model="locState.filterPqsBy"
                        column
                        multiple
                        filter
                        variant="outlined"
                        class="text-blue-darken-3"
                        selected-class="btn-primary"
                    >
                        <v-chip
                            v-for="option in options"
                            :key="option.id"
                            :value="option"

                        >
                            Show {{ option.title }} ({{ option.quantity }})
                        </v-chip>
                    </v-chip-group>
                    <acms-v-switcher
                        title="Simple PQ"
                        v-model="locState.simpleView"
                    />
                </div>
            </template>
            <template #tab-pqs>
                <div class="pq-tables-list">
                    <template v-for="(pqItemRow, index) in getFilteredCurrentActiveRequestPqsAgent">
                        <div class="pq-tables-item">
                            <div class="pq-tables-item-content d-flex">
                                <div class="pq-tables-item-content-inner">
                                    <PartialPriceQuotesTables
                                        :data="pqItemRow.data"
                                        :simple-view="locState.simpleView"
                                    />
                                </div>
                            </div>
                            <template v-if="pqItemRow.data.status !== 'Draft'">
                                <div class="pq-tables-item-actions gap-3 d-flex justify-content-end mt-4">
                                    <template v-if="pqItemRow.data.add_button && !pqItemRow.data.expired">
                                        <acms-v-btn :title="'Add ' + pqItemRow.data.add_button"
                                                    style="height: 31px"
                                                    v-if="backendPermissions?.allowCreateRequestPNRPQ"
                                                    class="btn-sm btn-dark"
                                                    @click="addPnrModal.modalShow = true"
                                        />
                                    </template>
                                    <ElementDropdownMoreOptions
                                        @select="(e)=>moreOptionsSelectHandle(e, pqItemRow.data)"
                                        :additional-option="getAdditionalOptionForMoreDropdown(pqItemRow.data)"
                                        :pqHiddenStatus="pqItemRow.data.hidden"
                                        :isExpired="pqItemRow.data.expired"
                                    />
                                    <acms-v-btn title="Send" class="btn-sm"
                                                v-if="backendPermissions?.allowSendRequestPQ && !pqItemRow.data.expired"
                                                style="height: 31px;"
                                                :class="locState.emailModalOpeningId == pqItemRow.data.id ? 'btn-primary' : 'btn-outline-primary'"
                                                :submitting="locState.emailModalOpeningId == pqItemRow.data.id"
                                                @click="()=>showSendEmailModal(pqItemRow.data.id)"/>
<!--                                    <SmsDialog :pq-id="pqItemRow.data.id"  :selected-pqs="locState.pqRowsChecked"/>-->
                                    <div v-if="!pqItemRow.data.expired" style="align-self: center; align-content: center; align-items: center">
                                        <v-checkbox
                                            style="padding: 0; margin: 0; height: 30px"
                                            density="compact"
                                            :model-value="isChecked(pqItemRow.data.id)"
                                            @change="(value) => handleCheckboxUpdate(value, pqItemRow.data.id)"
                                        />
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div style="display: flex; align-items: center; justify-content: center">
                                    <!-- Кнопка accept -->
                                    <v-btn
                                        @click="openDraftModeModal(pqItemRow.data)"
                                        text="accept"
                                        prepend-icon="mdi-check"
                                        color="success"
                                        style="margin-right: 10px"
                                    />

                                    <!-- Кнопка для открытия диалога -->
                                    <v-dialog v-model="dialogStates[pqItemRow.data.id]" max-width="500">
                                        <template v-slot:activator="{ props: activatorProps }">
                                            <v-btn
                                                   color="red"
                                                   v-bind="activatorProps"
                                                   prepend-icon="mdi-close"
                                                   text="decline"
                                                   style="margin-left: 10px"
                                            />
                                        </template>
                                        <!-- Содержимое диалога -->
                                        <v-card title="Confirm Decline">
                                            <v-card-text v-if="dialogStatesLoading[pqItemRow.data.id] === false">
                                                <!-- Поле ввода для указания причины -->
                                                <v-textarea
                                                    v-model="reason"
                                                    variant="outlined"
                                                    rows="5"
                                                    auto-grow
                                                    label="Reason"
                                                    :maxlength="1000"
                                                ></v-textarea>
                                            </v-card-text>

                                            <v-card-text v-else>
                                                <v-container class="d-flex justify-center align-center">
                                                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                                                </v-container>
                                            </v-card-text>
                                            <v-card-actions v-if="dialogStatesLoading[pqItemRow.data.id] === false">
                                                <v-spacer></v-spacer>
                                                <!-- Кнопка для закрытия диалога -->
                                                <v-btn text="Cancel" @click="dialogStates[pqItemRow.data.id] = false" />
                                                <v-btn text="Yes, decline" color="red" @click="handleDecline(pqItemRow.data.id)" />
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>

                                </div>
                            </template>

                        </div>
                    </template>
                </div>
            </template>
            <template #tab-all-pqs>
                <div class="pq-tables-list">
                    <template v-for="(pqItemRow) in getFilteredCurrentActiveRequestPqsPqs">
                        <div class="pq-tables-item">
                            <div class="pq-tables-item-content  d-flex ">
                                <div class="pq-tables-item-content-inner ">
                                    <PartialPriceQuotesTables
                                        :data="pqItemRow.data"
                                        :simple-view="locState.simpleView"
                                    />
                                </div>
                                <!--                                <div class="pq-tables-item-content-checkbox flex-column d-flex  ms-3">-->
                                <!--                                    <acms-v-switcher-->
                                <!--                                        type-checkbox-->
                                <!--                                        :model-value="Object.keys(locState.pqRowsChecked).includes(pqItemRow.data.id)"-->
                                <!--                                        @update:model-value="(e)=>selectPqRowHandle(e, pqItemRow.data.id)"-->
                                <!--                                    />-->
                                <!--                                </div>-->
                            </div>
                            <div class="pq-tables-item-actions gap-3 d-flex justify-content-end mt-4">
                                <template v-if="pqItemRow.data.add_button">
                                    <acms-v-btn :title="'Add ' + pqItemRow.data.add_button"
                                                v-if="backendPermissions?.allowCreateRequestPNRPQ"
                                                class="btn-sm btn-dark"
                                                @click="addPnrModal.modalShow = true"
                                    />
                                </template>

                                <ElementDropdownMoreOptions
                                    @select="(e)=>moreOptionsSelectHandle(e, pqItemRow.data)"
                                    :additional-option="getAdditionalOptionForMoreDropdown(pqItemRow.data)"
                                    :pqHiddenStatus="pqItemRow.data.hidden"
                                    :isExpired="pqItemRow.data.expired"
                                />
                                <acms-v-btn title="Send" class="btn-sm"
                                            v-if="backendPermissions?.allowSendRequestPQ"
                                            :class="locState.emailModalOpeningId == pqItemRow.data.id ? 'btn-primary' : 'btn-outline-primary'"
                                            :submitting="locState.emailModalOpeningId == pqItemRow.data.id"
                                            @click="()=>showSendEmailModal(pqItemRow.data.id)"/>
                                <div class="pq-tables-item-content-checkbox flex-column d-flex  ms-3" style="align-self: center">
                                    <acms-v-switcher
                                        type-checkbox
                                        :model-value="Object.keys(locState.pqRowsChecked).includes(pqItemRow.data.id)"
                                        @update:model-value="(e)=>selectPqRowHandle(e, pqItemRow.data.id)"
                                    />
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </template>
            <template #tab-exchange-pqs>
                Exchange PQ’s
            </template>
            <template #tab-refund-pqs>
                Refund PQ’s
            </template>
            <template #tab-tickets>
                Tickets PQ’s
            </template>
        </acms-v-tabs>

        <ModalCreateCommonPq
            v-if="commonPQModalShow"
            :clientId="clientId"
            :activeRequest="currentActiveRequestItem"
        />
        <!--  duplicate mode    -->
        <ModalCreateCommonPq
            v-if="locState.modalDuplicatePqShow"
            :clientId="clientId"
            :activeRequest="currentActiveRequestItem"
            :duplicate-from-pq="locState.modalDuplicatePqData"
            mode-duplicate
            :show="locState.modalDuplicatePqShow"
            @hide="hideModalDuplicatePqHandle"

        />
        <ModalCreateCommonPq
            v-if="locState.modalDraftModeShow"
            :clientId="clientId"
            :activeRequest="currentActiveRequestItem"
            :drafted-pq="locState.modalDraftModeData"
            mode-draft
            :update-mode="locState.updateMode"
            :show="locState.modalDraftModeShow"
            @hide="hideModalDraftModeHandle"
        />

        <ModalAddPnr
            :show="addPnrModal.modalShow"
            @hide="addPnrModal.modalShow = false"
            v-model:data="addPnrModal.data"
        />
        <ModalSendEmails v-if="locState.emailModalShow"
            v-model:show="locState.emailModalShow"
            :dataForForm="locState.emailModalData"
            :currentActiveRequestItem = "currentActiveRequestItem"
            @clearEmailData="emailModalClearData"
        />
        <ModalAddOrChangeLabel
            v-model:show="locState.modalLabelShow"
            :modelValue="locState.modalLabelData"
            @update:modelValue="changeLabelForPqHandle"
        />
        <ModalViewDump
            v-model:show="locState.modalViewDumpShow"
            :dumpCode="locState.modalViewDumpCode"
        />

    </div>
</template>
<script setup="">
import {storeToRefs} from "pinia";
import PartialPriceQuotesTables from './partials/PartialPriceQuotesTables.vue';
import {ElementDropdownNextActions, ElementDropdownMoreOptions, ElementDropdownAddPq} from "./elements";
import {ModalAddPnr, ModalCreateCommonPq,ModalViewDump,ModalAddOrChangeLabel,ModalSendEmails} from './modals';
import usePartialPriceQuotesComponent from "./PartialPriceQuotes.component";
import {PartialTabsCurrentSelectedRequest} from "@pages/requests/partials";
import {PQ_MANAGER_TABS_CONFIG} from "@pages/requests/partials/PartialPriceQuotes/constants";
import {usePageRequestShowStore} from "@store/pages/page-request-edit";
import ElementSendPqMulti from "./elements/ElementSendPqMulti.vue";
import {useBackendMiddlewarePropsService} from "@services";
import AcmsVBtn from "@ui/Btn.vue";
import {computed, ref, watch} from "vue";
import axios from "axios";
import SmsDialog from "@pages/requests/partials/PartialPriceQuotes/modals/SmsDialog.vue";
import {usePage} from "@inertiajs/vue3";
import {useAlertsManagerStore} from "@store/plugins/alerts-manager";
const emit = defineEmits([
    'changeActiveRequestTab'
]);

const props = defineProps({
    requests: Array,
    clientId: [String, Number],
    Tab: [String, Number],
    currentActiveRequestItem: Object,
    currentActiveRequestPqs: Array,
    currentActiveRequestAgentPqsArray: Array,
    pqs: Object,
    currentActiveRequestTab: Number,
    agents: [Array, Object]
});

const pageRequestShowStore = usePageRequestShowStore()
const {revenuePQModalShow, awardPQModalShow, commonPQModalShow} = storeToRefs(pageRequestShowStore)
const {backendPermissions} = useBackendMiddlewarePropsService()
const {
    locState, getSendEmails,
    showSendEmailModal, selectPqRowHandle,
    emailModalClearData,
    addPnrModal,
    changeLabelForPqHandle,
    moreOptionsSelectHandle,
    getAdditionalOptionForMoreDropdown,
    hideModalDuplicatePqHandle,
    hideModalDraftModeHandle,
    getFilteredCurrentActiveRequestPqsAgent,
    getFilterOptionQnt,
    openDraftModeModal,
    getFilteredCurrentActiveRequestPqsPqs,
} = usePartialPriceQuotesComponent({props});
const isChecked = (id) => {
    return !!locState.pqRowsChecked[id]; // Проверяем наличие значения в объекте
};
const dialogOpen = ref(false);

const openDialog = () => {
    dialogOpen.value = true;
};

const dialogStates = ref([]); // Массив состояний для каждого диалога
const dialogStatesLoading = ref([]); // Массив состояний для каждого диалога
const reason = ref('');
const initializeDialogs = () => {
    getFilteredCurrentActiveRequestPqsAgent.value.forEach((i) => {
        dialogStates.value[i.data.id] = false;  // Диалог закрыт по умолчанию
        dialogStatesLoading.value[i.data.id] = false;  // Диалог закрыт по умолчанию
        // Пустая причина по умолчанию
    });
};

initializeDialogs();
const AlertsManager = useAlertsManagerStore()
const assignSelf = async () => {
    try {
        const response = await axios.post('/requests/assign-self', {
            request_id : props.currentActiveRequestItem.id
        })
        window.location.reload();
        return response.data; // реактивно возвращаем результат
    } catch (error) {
        console.error('Ошибка при отправке запроса:', error.response?.data?.error ?? 'error ');
        const err =  error.response.data.error ?? 'error ';
        AlertsManager.add(err, 'danger');
      setTimeout(() => {
          window.location.reload();
      }, 5000); // 5000 миллисекунд = 5 секунд

        return null; // возвращаем null при ошибке
    }
};
const showTakeButton = computed(() => {
    const allAgentsAreSystem = props.agents.length > 0 && props.agents.every(agent => agent.user_type === "System");
    return (props.agents.length < 1 || allAgentsAreSystem) && backendPermissions.allowTakeUnassigned;
});

// Метод для отправки данных через Axios

const page = usePage();
const handleDecline = async (id) => {
    dialogStatesLoading.value[id] = true;
    try {
        const response = await axios.post('/api/pq/reject', {
            id: id,       // Используем значение переменной id
            reason: reason.value, // Используем введенную причину
            agent_mail : page.props.auth_user.work_data.work_email
        });

        // Закрытие диалога после успешной отправки
        dialogStates.value[id]= false;
        window.location.reload();
        // Обработка ответа
    } catch (error) {
        console.error('Ошибка при отправке запроса:', error);
    }
    finally {
        dialogStatesLoading.value[id] = false;
    }
};

const handleCheckboxUpdate = (event, id) => {
    const checked = event.target.checked;

    if (checked) {
        // Добавляем новый ключ и присваиваем значение на основе текущего количества ключей
        locState.pqRowsChecked[id] = Object.keys(locState.pqRowsChecked).length + 1;
    } else {
        // Удаляем ключ, если он уже есть
        delete locState.pqRowsChecked[id];

        // Пересчитываем значения для каждого оставшегося ключа
        let counter = 1;
        Object.keys(locState.pqRowsChecked).forEach(key => {
            locState.pqRowsChecked[key] = counter++;
        });
    }
};
const options = ref([
    { id: 1, title: 'Hidden', quantity: computed(() => getFilterOptionQnt.value.hidden.toString()) },
    { id: 2, title: 'Sent', quantity: computed(() => getFilterOptionQnt.value.sent.toString()) },
    // Добавьте другие опции по мере необходимости
])
const toggleFilter = (filter) => {
    // Убедимся, что locState.filterPqsBy существует или инициализируем его пустым массивом
    if (!locState.filterPqsBy) {
        locState.filterPqsBy = [];
    }

    const index = locState.filterPqsBy.findIndex(item => item.id === filter.id);

    if (index !== -1) {
        locState.filterPqsBy.splice(index, 1);
    } else {
        locState.filterPqsBy.push(filter);
    }
}


</script>
<style lang="scss">
@import "styles";
.v-selection-control {
    flex-direction: column!important;
}

</style>
